<template>
  <div id="app">
    <keep-alive>
		<router-view v-if="$route.meta.keepAlive"></router-view>
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { getOrderStatus , getOrderId } from './api/index.js'
import { Toast } from 'vant';

export default{
	data(){
		return{
			order_id: '',
			key: '',
		}
	},
	created(){
		// let key = this.getQueryKey();
		// this.key = key;
		// if(!localStorage.getItem(this.key)){
		// 	this.clearLocalStorage()
		// }
		// console.log('APP.vue的key',key);
  //       getOrderId({
  //           key
  //       }).then( res => {
  //           console.log('app获取order_id的res',res);
		// 	if(res.code == 100){
		// 		this.order_id = res.data.arr.order_id;
		// 		localStorage.setItem(this.key,res.data.arr.order_id);
		// 	}
  //       })
		// if(!this.order_id && !localStorage.getItem(this.key)){
		// 	return
		// }
		// // console.log('order_id@@@@@@@@@',this.order_id);
		// getOrderStatus({
		// 	order_id: this.order_id ? this.order_id : localStorage.getItem(this.key)
		// }).then( res => {
		// 	// console.log('查看订单状态的res',res);
		// 	if(res.code == 100){
		// 		if(res.data.status == 1){
		// 			this.$router.push({
		// 				path: "/",
		// 			});
		// 		} else if(res.data.driver_id){
		// 			this.$router.push({
		// 				path: "/orderProcess",
		// 				query: {
		// 					key: this.key,
		// 					order_id: this.order_id,
		// 					status: res.data.status,
		// 					contact_time: res.data.contact_time,
		// 					cancel: res.data.cancel,
		// 					id: res.data.driver_id
		// 				}
		// 			});
		// 		} else if(res.driver_id){
		// 			this.$router.push({
		// 				path: "/orderProcess",
		// 				query: {
		// 					key: this.key,
		// 					order_id: this.order_id,
		// 					status: res.data.status,
		// 					contact_time: res.data.contact_time,
		// 					cancel: res.data.cancel,
		// 					id: res.driver_id
		// 				}
		// 			});
		// 		}
		// 	} else {
		// 		Toast({
		// 			message: res.msg,
		// 		});
		// 	}
		// })
	},
	methods: {
		//截取Url参数方法
        getQueryKey() {
            var reg = new RegExp("(^|&)([A-Z]+)(#|/|&|$|[a-z]|[0-9])");			
            var searchStr = decodeURIComponent(window.location.href.substr(window.location.href.indexOf('?')))
            var r = searchStr.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            console.log(r)
            return null;
        },
		clearLocalStorage() {
			for (var i = localStorage.length - 1; i >= 0; i--) {
				var key = localStorage.key(i);
				if (!key.startsWith("_AMap")) {
				localStorage.removeItem(key);
				}
			}
		}
	}
}
</script>
<style lang="less">
  body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	hr,
	p,
	blockquote,
	/* structural elements 结构元素 */
	dl,
	dt,
	dd,
	ul,
	ol,
	li,
	/* list elements 列表元素 */
	pre,
	/* text formatting elements 文本格式元素 */
	fieldset,
	lengend,
	button,
	input,
	textarea,
	/* form elements 表单元素 */
	th,
	td {
		/* table elements 表格元素 */
		margin: 0;
		padding: 0;
	}

	page {
		padding-bottom: constant(safe-area-inset-bottom);
		padding-bottom: env(safe-area-inset-bottom);
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	.amap-logo {
		display: none;
		opacity: 0 !important;
	}
	.amap-copyright {
		opacity: 0;
	}
</style>
