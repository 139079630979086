//统一封装接口
import request from '@/utils/request.js'
import requests from '@/utils/requests.js'
// import qs from "qs"

//获取司机信息
export const getDriverInfo = ({id,order_id}) => request({
	url:'/driver/order/driverStroke',
	method:'POST',
	params: {
		id,
		order_id
	}
})
//更新司机位置
export const updateDriverPosition = ({position,order_id}) => request({
	url:'/driver/order/updateUserLocator',
	method:'GET',
	params: {
		position,
		order_id
	}
})
//取消订单
export const cancelOrder = ({order_id,ad_code,is_confirm}) => requests({
	url:'/user/order/cancelOrdernews',
	method:'POST',
	data: {
		order_id,
		is_confirm,
		ad_code,
	}
})
//验证该用户是否有权限访问连接
export const AccessAuthority = ({order_id,unique,driver_id,tail_number}) => request({
	url:'/driver/login/checkUserInfo',
	method:'POST',
	params: {
		order_id,
		// unique,
		driver_id,
		tail_number
	}
})
//位置验证/推单
export const postLocation = ({order_id,lng,lat,aid,is_repush,target_lng,target_lat,target_address,place_address,place_address_title,target_address_title,}) => requests({
	url:'/user/order/smspushorder',
	method:'POST',
	data: {
		order_id,
		lng,
		lat,
		aid,
		is_repush,
		target_lng,
		target_lat,
		target_address,
		place_address,
		place_address_title,
		target_address_title
	}
})
//搜索结果列表
export const getSearchList = ({cid,keyword}) => requests({
	url:'/user/order/getkeywordlist',
	method:'POST',
	data: {
		cid,
		keyword
	}
})
//获取订单状态
export const getOrderStatus = ({order_id}) => requests({
	url:'/user/order/getOrderStatus',
	method:'POST',
	data: {
		order_id
	}
})
//获取订单id
export const getOrderId = ({key}) => requests({
	url:'/user/order/getlinkparam',
	method:'POST',
	data: {
		key
	}
})

//获取小程序跳转链接
export const getScheme = ({path,query,env_version}) => requests({
	url:'/user/getscheme',
	// method:'POST',
	params: {
		path,
		query,
		env_version
	}
})

//获取微信接口配置
export const getWxConfig = ({url}) => requests({
	url:'/user/wxpay/getSignPackage',
	params: {
		url
	}
	// method:'POST',
})
// export const getWxConfig = ({url}) => requests({
// 	url:'/user/getSignPackage',
// 	params: {
// 		url
// 	}
// 	// method:'POST',
// })