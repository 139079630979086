const router_modular = [{
		path: '/',
		name: 'index',
		component: () => import('@/page/index/index.vue'),
		meta: {
			keepAlive: false // 需要缓存
		}
	},
	{
		path: '/selectLocation',
		name: 'selectLocation',
		component: () => import('@/page/select-location/index.vue'),
		meta: {
			keepAlive: false // 需要缓存
		}
	},
	{
		path: '/orderProcess',
		name: 'orderProcess',
		component: () => import('@/page/order-process/index.vue'),
		meta: {
			keepAlive: false // 需要缓存
		}
	},
	{
		path: '/awaitOrder',
		name: 'awaitOrder',
		component: () => import('@/page/awaitOrder/index.vue'),
		meta: {
			keepAlive: false // 需要缓存
		}
	},
	// {
	// 	path: '/androidToWeixin',
	// 	name: 'androidToWeixin',
	// 	component: () => import('@/page/androidToWeixin/index.vue'),
	// 	meta: {
	// 		keepAlive: false // 需要缓存
	// 	}
	// }
]
export default router_modular
