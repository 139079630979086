import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'amfe-flexible'
import { Progress,CountDown } from 'vant';
Vue.use(Progress);
Vue.use(CountDown);
Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  axios,
  render: h => h(App),
}).$mount('#app')
